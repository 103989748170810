import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible/flexible";
import Http from "./utils/http";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from "vue-baidu-map";
import VueI18n from "vue-i18n";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";

// import './assets/css/iconfont.css'
// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可单独引用
// import Base64 from 'js-base64'

// zzp mock
import "./mock/index.js";

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.L = Vue.prototype.$L = L;

Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(BaiduMap, { ak: "Mi1KnxHMOWoVZLRBUHvGj9YN0Mv1lp9Y" });
Vue.use(VueI18n);

Vue.prototype.$http = Http;
Vue.config.productionTip = false;
Vue.config.devtools = true;

// Vue.prototype.$Base64 = Base64;

// let consoleTool = new Vconsole();
const i18n = new VueI18n({
  locale: "zh", // 定义默认语言为中文
  messages: {
    zh: require("./assets/i18n/local/zh.json"),
    en: require("./assets/i18n/local/en.json"),
  },
});

window.consoleButton = true;

window.rewirteLog = function () {
  console.log = (function (log) {
    return window.consoleButton ? log : function () {};
  })(console.log);
};

window.logOpen = function () {
  var iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);
  console = iframe.contentWindow.console;
  window.console = console;
};

window.rewirteLog();

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
